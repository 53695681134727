























































































import { Vue, Component, Provide } from 'vue-property-decorator';
import { Dialog, Toast } from 'vant';
import serve from '@/serve/goods';
@Component({})
export default class Store extends Vue {
  @Provide() path = this.$store.state.headerPath;
  @Provide() goodsDetail = {};
  @Provide() show = false;
  @Provide() rate = 3.5;
  @Provide() cover = require('@/assets/img/bigcover.jpg');
  @Provide() QQdata = []
  @Provide() showDia = false
  created() {
    this.getDetail();
  }
  async getDetail() {
    const res = await serve.getGoodsDetail(this.$route.query.id.toString());
    res.data.data.pic = res.data.data.pictures.split(',');
    this.goodsDetail = res.data.data;
    this.QQdata = res.data.data.qq
  }

  private goBack(): void {
    this.$router.push(this.path);
  }
  private order(): void {
    this.show = true;
  }
  // 拉起手机qq
  openQQ(val?: string): void {
    window.location.href = `mqqwpa://im/chat?chat_type=wpa&uin=${val}&version=1&src_type=web&web_src=oicqzone.com`;
  }
  private goQQ(): void {
    // this.QQdata = val['qq'];
    this.showDia = true;
  }
  async defineOrder() {
    const data = {
      merchandiseId: this.goodsDetail['id'],
    };
    const res = await serve.submitOrder(data);
    if (res.data.code === 0) {
      Dialog.alert({
        message: '下单成功，请立即联系商家',
      }).then(() => {
        // on close
      });
    } else {
      Toast.fail(res.data.message);
    }
  }
}

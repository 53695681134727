import { http } from '@/utils/http'
export default {
    getGoodsDetail(params: string | null){
        // 获取商品详情
        return http({
            url:`/members/api/v1/merchandise/${params}`,
            method:'get'
        })
    },
    submitOrder(params: object){
        // 下单
        return http({
            url:`/members/api/v1/orders`,
            method:'post',
            data:params
        })
    },
}